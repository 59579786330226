import './App.css';
import data from './Data';
import React from 'react';
import {T} from './PopUpJK';

function App() {

  return (
    <div className="App-header">
      <h1>Jinkyu James Kim's Front-end Portfolios</h1>
      <h3>Static Web Pages (click)</h3>
      <div style={{width:'650px',display:'flex',flexDirection:'row', flexWrap:'wrap'}} >
        {
          data.map(p => 
            <>
             <a
              className="App-link"
              href={p.url}
              target="_blank"
              rel="noopener noreferrer"
              key={p.id}
            >
              <T r={<img src={p.img} alt={p.title} style={{width:'200px',height:'200px'}} />} s={p.title} w={70} t={-70} l={50}/>
            </a>
            &nbsp;
            </>
          )
        }    
      </div>
      <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
        <T r={<p>320 South Harrison St., 16B, East Orange, NJ 07018</p>} s='Address' t={0} l={220} />
        < T r={<p>609-578-0922</p>} s='phone number' w={100} t={0}  l={10} />
        < T r={<p>jinkyujames@gmail.com</p>} s='Email address' w={100} t={0}  l={50} />
        < T r={<a href='https://github.com/jinkyumihyun/jinkyu-solution.git'style={{color:'white'}} >Github pages</a>} s='github address' w={100} t={-20}  l={10} />
       
      </div>
      
    </div>
  );
}
//<a href='https://localhost:3000/Jinkyu0.docx' download>resume</a>
export default App;
