import {nanoid} from 'nanoid';
import Todos from './images/todolist.png';
import Emojis from './images/emojis.png';
import Recipes from './images/recipes.png';
import Candies from './images/candies.png';
import Cocktails from './images/cocktails.png';
import Contacts from './images/contacts.png';
import Notes from './images/notes.png';
import Quizzes from './images/quizzes.png';
import TicTacTo from './images/tictacto.png';

const data = [
{id:nanoid(), title:"Note threads", img:Todos, url:'https://todolist.jkportfolios.net', text:"",},
{id:nanoid(), title:"Emojis", img:Emojis, url:'https://emojis.jkportfolios.net', text:"",},
{id:nanoid(), title:"Recipes", img:Recipes, url:'https://recipes.jkportfolios.net', text:"",},
{id:nanoid(), title:"Candy Crush", img:Candies, url:'https://candies.jkportfolios.net', text:"",},
{id:nanoid(), title:"Cocktails", img:Cocktails, url:'https://cocktails.jkportfolios.net', text:"",},
{id:nanoid(), title:"Contacts", img:Contacts, url:'https://contactlist.jkportfolios.net', text:"",},
{id:nanoid(), title:"Note(Redux)", img:Notes, url:'https://notes.jkportfolios.net', text:"",},
{id:nanoid(), title:"Quizzes", img:Quizzes, url:'https://quizzes.jkportfolios.net',text:"",},
{id:nanoid(), title:"TicTacTo",img:TicTacTo, url:'https://tictacto.jkportfolios.net',text:"",},
];
export default data;